import { cx } from '@emotion/css';
import { CloseRounded } from '@mui/icons-material';
import type { DialogProps } from '@mui/material';
import MiuDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import type { FC, ReactNode } from 'react';

import { Button } from '../Button';
import * as S from './styles';

type Props = {
  allowOverflowVisibility?: boolean;
  headline?: ReactNode;
  actionDialog?: boolean;
  closeBtn?: boolean;
  ariaLabel?: string;
  ariaDescription?: string;
  headerClasses?: string;
  handleClose?: () => void;
  actions?: ReactNode;
} & DialogProps;

const defaultProps = {
  ariaLabel: 'alert-dialog-title',
  ariaDescription: 'alert-dialog-description',
  fullScreen: false,
  actionDialog: true,
  closeBtn: true,
  allowOverflowVisibility: false,
};

export const Dialog: FC<Props> = (props) => {
  const {
    handleClose,
    allowOverflowVisibility,
    closeBtn,
    children,
    headerClasses,
    headline,
    actionDialog,
    ariaLabel,
    ariaDescription,
    actions,
    ...restProps
  } = {
    ...defaultProps,
    ...props,
  };

  return (
    <S.Dialog
      allowOverflowVisibility={allowOverflowVisibility}
      maxWidth={'lg'}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
      scroll={'body'}
      PaperProps={{
        style: {
          minHeight: actionDialog ? 'none' : '37.5rem',
        },
      }}
      onClose={() => {
        handleClose && handleClose();
      }}
      {...restProps}>
      <MuiDialogContent
        data-testid="dialog-content"
        className={cx('p-0', actions == null && 'layout-column layout-fill')}>
        {closeBtn || headline !== undefined ? (
          <header
            data-testid="dialog-header"
            className={cx(
              headline ? 'layout-align-space-between-center' : 'layout-align-end-center',
              'layout-row',
              headerClasses,
            )}>
            {headline}
            {closeBtn && (
              <Button
                buttonStyle={'secondary'}
                fab
                size={'xs'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose && handleClose();
                }}
                icon={<CloseRounded />}
                iconSize={'md'}
                className={'bg-secondary-200 text-secondary-700'}
                data-testid={'dialog-header-close-button'}
              />
            )}
          </header>
        ) : null}
        {children}
      </MuiDialogContent>
      {actions != null && <MiuDialogActions className="p-0">{actions}</MiuDialogActions>}
    </S.Dialog>
  );
};
