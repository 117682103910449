import { cx } from '@emotion/css';
import { CloseRounded } from '@mui/icons-material';
import { Button, useMediaQuery } from '@sortlist-frontend/design-system';
import { FC, ReactElement, ReactNode } from 'react';

import * as S from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg';
  footer?: ReactNode;
  header?: ReactNode;
  ariaLabel?: string;
  ariaDescription?: string;
  actionDialog?: boolean;
  children: ReactElement;
};

const defaultProps = {
  ariaLabel: 'alert-dialog-title',
  ariaDescription: 'alert-dialog-description',
};

export const Dialog: FC<Props> = (props) => {
  const { open, onClose, children, maxWidth, footer, header, actionDialog, ariaLabel, ariaDescription } = {
    ...defaultProps,
    ...props,
  };
  const { media } = useMediaQuery();
  return (
    <S.StyledDialog
      handleClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth={true}
      headerClasses="px-16 pt-16"
      fullScreen={!!media.xs}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
      scroll={'body'}
      PaperProps={{
        style: {
          minHeight: actionDialog ? 'none' : '37.5rem',
        },
      }}>
      <div className="p-0 layout-column layout-fill">
        <div className="layout-column flex overflow-auto">
          <div className={cx(header ? 'layout-align-start-center' : 'layout-align-end-center p-24', 'layout-row')}>
            {header ? header : null}
          </div>
          {children}
        </div>
        {footer ? footer : null}
      </div>
    </S.StyledDialog>
  );
};
